.stickyNav {
  position: fixed;
  top: 50vh;
  left: 2vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem !important;
  transform: translate(-50%, -50%);
  line-height: 0;
}
.stickySocialItem {
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 50%;
  overflow: hidden;
}
.stickyNavItem {
  padding: 0.1rem;
  border-radius: 50%;
  background-color: #fff;
}
.stickyNavIcon {
  height: 0.7rem;
  width: 0.7rem;
  color: var(--color-black);
  transition: all 0.2s ease-in-out;
}
.stickySocial {
  position: fixed;
  top: 50vh;
  right: 1vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transform: translate(-50%, -50%);
  line-height: 0;
  z-index: 5;
}

.stickysocialicon {
  height: 1.25rem;
  width: 1.25rem;
  color: #4c3ca0;
  transition: all 0.2s ease-in-out;
}

.stickysocialicon:hover,
.stickyNavIcon:hover {
  transform: scale(1.3);
}
