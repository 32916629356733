@import "https://fonts.googleapis.com/css?family=Open+Sans";

h1 {
	font-weight: 300;
}

.title {
	margin-bottom: 25px;
	color: #aeb6bf;
	font-size: 18px;
}
.title span {
	color: #5dade2;
}
.content {
	width: 640px;
	margin: 45px auto;
}
.tabs-body {
	background: var(--color-white);
	border-radius: 50px;
}
.tabs-header {
	display: table;
	width: 100%;
	list-style-type: none;
}
.tabs-header li {
	display: table-cell;
	text-align: center;
	color: black;
	cursor: pointer;
}
.tabs-header li > a {
	display: block;
	padding: 15px;
	background: var(--color-white);
	transition: all 0.2s ease-in;
	border-radius: 50px;
	width: 100%;
}
.tabs-header li > a span {
	display: block;
	width: 100%;
	height: 100%;
}

.tabs-header li > a span:hover {
	display: block;
	color: white;
}
.tabs-header li > a:hover {
	background: #433589;
	color: #fff;
}
.tabs-header li > a:hover span {
	color: #fff;
}
.tabs-header li.active > a {
	background: var(--color-secondary);
	color: white;
}
.tabs-content {
	margin-top: 15px;
	position: relative;
	padding: 20px 25px;
	overflow: hidden;
	background: var(--color-black);
}
.tabs-content .tabs-textItem {
	display: none;
	transform: translateY(100%);
}
.tabs-content .tabs-textItem.show {
	display: block;
	transform: translateY(0);
	animation: change 0.49s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.tabs-content .tabs-textItem p {
	font: normal 15px/1.5 "Open Sans";
	color: #f2f3f4;
}

@keyframes change {
	0% {
		transform: translateY(100%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@media screen and (max-width: 500px) {
	.tabs-header {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		list-style-type: none;
	}
	.tabs-header li {
		display: table-cell;
		text-align: center;
		flex-basis: 50%;
		color: black;
		cursor: pointer;
	}
	.tabs-header li > a {
		display: block;
		padding: 15px;
		background: var(--color-white);
		transition: all 0.2s ease-in;
		border-radius: 20px;
	}
	.tabs-header li > a span {
		display: block;
	}

	.tabs-header li > a span:hover {
		display: block;
		color: white;
	}
	.tabs-header li > a:hover {
		background: #433589;
		color: #fff;
	}

	.tabs-header li > a:hover span {
		color: #fff;
	}
	.tabs-header li.active > a {
		background: var(--color-secondary);
		color: white;
	}
	.tabs-body {
		background: var(--color-white);
		border-radius: 20px;
	}
}
