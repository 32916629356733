/*
0-600px         Phone
600-900px       Tablet Potrait Mode
900-1200px      Tablet Landscape Mode
1200-1800px     Normal styles apply

1800px +        Big Desktop

ORDER:- Base + Typography > General Layout + Grid > Page Layout > Components

$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop
*/

.row > * {
  padding: 0;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10em);
  }
  80% {
    transform: translateX(1em);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10em);
  }
  80% {
    transform: translateX(-1em);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3em);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

*,
*::after {
  margin: 0;
  padding: 0;
}

.homeEvent {
  text-align: left;
  padding: 6rem 6rem;
  background-color: rgb(14, 14, 14);
  color: white;
  height: 750px;
  font-family: "Lato", sans-serif;
  font-size: 62.5%;
  font-weight: 400;
  line-height: 1.7;
}
@media only screen and (max-width: 75em) {
  .homeEvent {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 56.25em) {
  .homeEvent {
    font-size: 50%;
  }
}
@media only screen and (min-width: 112.5em) {
  .homeEvent {
    font-size: 75%;
  }
}

@media only screen and (max-width: 56.25em) {
  .homeEvent {
    padding: 2.5rem;
  }
}

::selection {
  background-color: #55c57a;
  color: #fff;
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-big {
  margin-bottom: 3em !important;
}
/* @media only screen and (max-width: 56.25em) {
  .u-margin-bottom-big {
    margin-bottom: 5em !important;
  }
} */

.u-margin-bottom-small {
  margin-bottom: 1.5em !important;
}

.u-margin-bottom-medium {
  margin-bottom: 4em !important;
}
@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-medium {
    margin-bottom: 3em !important;
  }
}

.u-margin-top-big {
  margin-top: 8em !important;
}

.u-margin-top-huge {
  margin-top: 10em !important;
}

.heading-primary {
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 6em;
  backface-visibility: hidden;
}
.heading-primary--main {
  display: block;
  font-size: 6em;
  font-weight: 400;
  letter-spacing: 3.5em;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  /* animation-delay: 3s;
      animation-iteration-count: 3; */
}
@media only screen and (max-width: 37.5em) {
  .heading-primary--main {
    letter-spacing: 1em;
    font-size: 5em;
  }
}
.heading-primary--sub {
  display: block;
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 1.2em;
  animation: moveInRight 1s ease-out;
}
@media only screen and (max-width: 37.5em) {
  .heading-primary--sub {
    letter-spacing: 0.5em;
  }
}

.heading-secondary,
.home-section-heading,
.faculty-advisor-text {
  font-size: 2.6rem;
  color: #fff;
  font-weight: 800;
  position: relative;
}

.heading-secondary::after,
.home-section-heading::after {
  content: " ";
  display: inline-block;
  background-image: linear-gradient(
    to right,
    #fff 50%,
    #000 50%,
    #000 52%,
    var(--color-secondary) 50%
  );
  height: 0.5rem;
  border-radius: 2rem;
  width: 10rem;

  position: absolute;
  top: 120%;
  transform: translate(-50%, -50%);
  left: 50%;
}

@media only screen and (max-width: 56.25em) {
  .heading-secondary,
  .home-section-heading {
    font-size: 3em;
  }
}
@media only screen and (max-width: 37.5em) {
  .heading-secondary,
  .home-section-heading {
    font-size: 2.5em;
  }
}

.heading-tertiary {
  color: var(--color-secondary);
  font-size: 1.4rem;
  font-weight: 800;
  text-transform: uppercase;
}

.paragraph {
  font-size: 1.6em;
  color: white;
}
.paragraph:not(:last-child) {
  margin-bottom: 3em;
}

.section-feature {
  padding: 20em 0;
  background-size: cover;
  transform: skewY(-7deg);
  margin-top: -10em;
}
.section-feature > * {
  transform: skewY(7deg);
}
@media only screen and (max-width: 56.25em) {
  .section-feature {
    padding: 10em 0;
  }
}

.section-tours {
  background-color: #f7f7f7;
  padding: 25em 0 15em 0;
  margin-top: -10em;
}
@media only screen and (max-width: 56.25em) {
  .section-tours {
    padding: 20em 0 10em 0;
  }
}

.section-stories {
  padding: 15em 0;
  position: relative;
}
@media only screen and (max-width: 56.25em) {
  .section-stories {
    padding: 10em 0;
  }
}

.section-form {
  padding: 15em 0;
  background-image: linear-gradient(to right bottom, #7ed56f, #28b485);
}
@media only screen and (max-width: 56.25em) {
  .section-form {
    padding: 10em 0;
  }
}

.book {
  background-size: 100%;
  border-radius: 3px;
  box-shadow: 0 1.5em 4em rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 75em) {
  .book {
    background-size: cover;
  }
}
@media only screen and (max-width: 56.25em) {
}
.book__form {
  width: 50%;
  padding: 6em;
}
@media only screen and (max-width: 75em) {
  .book__form {
    width: 65%;
  }
}
@media only screen and (max-width: 56.25em) {
  .book__form {
    width: 80%;
  }
}

.btn,
.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5em 4em;
  display: inline-block;
  border-radius: 10em;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6em;
  border: none;
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-0.3em);
  box-shadow: 0 1em 2em rgba(0, 0, 0, 0.2);
}
.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn:active,
.btn:focus {
  transform: translateY(-0.1em);
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
}

.btn--white {
  background-color: #fff;
  color: #777;
}
.btn--white::after {
  background-color: #fff;
}

.btn--green {
  background-color: #55c57a;
  color: #fff;
}
.btn--green::after {
  background-color: #55c57a;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10em;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn--animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.btn-text:link,
.btn-text:visited {
  color: var(--color-secondary);
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid var(--color-secondary);
  padding: 3px;
  font-size: 1.6em;
  transition: all 0.2s;
}

.btn-text:hover {
  background-color: var(--color-secondary);
  color: #fff;
  box-shadow: 0 1em 2em rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.btn-text:active {
  box-shadow: 0 0.5em 2em rgba(0, 0, 0, 0.15);
  transform: translateY(0);
}

.composition {
  position: relative;
}
.composition__photo {
  width: 55%;
  box-shadow: 0 1.5em 4em rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  transition: all 0.2s;
  outline-offset: 2em;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo {
    float: left;
    position: relative;
    width: 33.333%;
    box-shadow: 0 1.5em 3em rgba(0, 0, 0, 0.2);
  }
}
.composition__photo--p1 {
  left: 0;
  top: -2em;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo--p1 {
    top: 0;
    transform: scale(1.2);
  }
}
.composition__photo--p2 {
  right: 0;
  top: 3em;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo--p2 {
    top: -2em;
    transform: scale(1.3);
    z-index: 100;
  }
}
.composition__photo--p3 {
  left: 20%;
  top: 10em;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo--p3 {
    top: 0;
    left: 0;
    transform: scale(1.2);
  }
}
.composition__photo:hover {
  transform: scale(1.05) translateY(-5px);
  box-shadow: 0 2.5em 4em rgba(0, 0, 0, 0.5);
  z-index: 20;
}
.composition:hover .composition__photo:not(:hover) {
  transform: scale(0.95);
}

.feature-box {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.5em;
  padding: 2.5em;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 1.5em 4em rgba(0, 0, 0, 0.15);
  transition: all 0.3s;
}
@media only screen and (max-width: 56.25em) {
  .feature-box {
    padding: 2em;
  }
}
.feature-box__icon {
  font-size: 6em;
  margin-bottom: 0.5em;
  background-image: linear-gradient(to right, #7ed56f, #28b485);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
@media only screen and (max-width: 56.25em) {
  .feature-box__icon {
    padding: 0;
  }
}
.feature-box:hover {
  transform: translateY(-1.5em) scale(1.03);
}

.card {
  perspective: 150em;
  -moz-perspective: 150em;
  position: relative;
  height: 52em;
}
.card__side {
  transition: all 0.8s ease-out;
  height: 52em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  backface-visibility: hidden;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5em 4em rgba(0, 0, 0, 0.15);
}
.card__side--front {
  background-color: #fff;
}
.card__side--back {
  transform: rotateY(180deg);
}
.card__side--back-1 {
  background-image: linear-gradient(to right bottom, #ffb900, #ff7730);
}
.card__side--back-2 {
  background-image: linear-gradient(to right bottom, #7ed56f, #28b485);
}
.card__side--back-3 {
  background-image: linear-gradient(to right bottom, #2998ff, #5643fa);
}
.card:hover .card__side--front {
  transform: rotateY(-180deg);
}
.card:hover .card__side--back {
  transform: rotateY(0);
}
.card__image {
  background-size: cover;
  height: 23em;
  background-blend-mode: screen;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
}
.card__heading {
  font-size: 2.8em;
  font-weight: 300;
  text-transform: uppercase;
  color: #fff;
  text-align: right;
  position: absolute;
  top: 12em;
  right: 3em;
  width: 72%;
}
.card__heading-span {
  padding: 1em 1.5em;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
.card__heading-span--1 {
  background-image: linear-gradient(
    to right bottom,
    rgba(255, 185, 0, 0.85),
    rgba(255, 119, 48, 0.85)
  );
}
.card__heading-span--2 {
  background-image: linear-gradient(
    to right bottom,
    rgba(126, 213, 111, 0.85),
    rgba(40, 180, 133, 0.85)
  );
}
.card__heading-span--3 {
  background-image: linear-gradient(
    to right bottom,
    rgba(41, 152, 255, 0.85),
    rgba(86, 67, 250, 0.85)
  );
}
.card__details {
  padding: 3em;
}
.card__details ul {
  list-style: none;
  width: 80%;
  margin: 0 auto;
}
.card__details li {
  text-align: center;
  font-size: 1.5em;
  padding: 1em;
}
.card__details li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.card__cta {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}
.card__price-box {
  text-align: center;
  color: #fff;
  margin-bottom: 8em;
}
.card__price-only {
  font-size: 1.4em;
  text-transform: uppercase;
}
.card__price-value {
  font-size: 6em;
  font-weight: 100;
}
@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  .card {
    height: auto;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 1.5em 4em rgba(0, 0, 0, 0.15);
  }
  .card__side {
    height: auto;
    position: relative;
    box-shadow: none;
  }
  .card__side--back {
    transform: rotateY(0deg);
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
  }
  .card:hover .card__side--front {
    transform: rotateY(0);
  }
  .card__cta {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0);
    padding: 7em 4em 4em 4em;
  }
  .card__price-box {
    text-align: center;
    color: #fff;
    margin-bottom: 3em;
  }
  .card__price-value {
    font-size: 6em;
    font-weight: 100;
  }
}

.story {
  width: 75%;
  margin: 0 auto;
  box-shadow: 0 3em 6em rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  padding: 6em;
  padding-left: 9em;
  font-size: 1.6em;
  transform: skewX(-12deg);
}
@media only screen and (max-width: 56.25em) {
  .story {
    padding: 4em;
    padding-left: 7em;
  }
}
@media only screen and (max-width: 37.5em) {
  .story {
    transform: skew(0);
  }
}
.story > * {
  transform: skewX(12deg);
}
@media only screen and (max-width: 37.5em) {
  .story > * {
    transform: skew(0);
  }
}
.story__shape {
  position: relative;
  overflow: hidden;
  float: left;
  border-radius: 50%;
  transform: translateX(-3em) skewX(12deg);
}
@supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
  .story__shape {
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    width: 15em;
    height: 15em;
    -webkit-shape-outside: circle(50% at 50% 50%);
    shape-outside: circle(50% at 50% 50%);
    border-radius: none;
  }
}
@media only screen and (max-width: 37.5em) {
  .story__shape {
    transform: translateX(-3em) skew(0);
  }
}
.story__image {
  height: 100%;
  transform: translateX(-4em) scale(1.4);
  backface-visibility: hidden;
  transition: all 0.5s;
}
.storytext {
  transform: skewX(12deg);
}
@media only screen and (max-width: 37.5em) {
  .storytext {
    transform: skew(0);
  }
}
.story__caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 20%);
  color: white;
  text-transform: uppercase;
  font-size: 1.7em;
  text-align: center;
  opacity: 0;
  transition: all 0.5s;
  backface-visibility: hidden;
}
.story:hover .story__caption {
  opacity: 1;
  transform: translate(-50%, -50%);
}
.story:hover .story__image {
  transform: translate(-4em) scale(1);
  filter: blur(3px) brightness(80%);
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.25;
  overflow: hidden;
}
.bg-video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.form__group:not(:last-child) {
  margin-bottom: 2em;
}

.form__input {
  font-size: 1.5em;
  font-family: inherit;
  color: inherit;
  padding: 1.5em 2em;
  border-radius: 2px;
  background-color: rbga(#fff, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 69%;
  display: block;
  transition: all 0.3s;
}
@media only screen and (max-width: 56.25em) {
  .form__input {
    width: 87%;
  }
}
.form__input::-webkit-input-placeholder {
  color: #999;
}
.form__input:focus {
  outline: none;
  box-shadow: 0 1em 2em rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input:focus:invalid {
  border-bottom: 3px solid #ff7730;
}

.form__label {
  font-size: 1.2em;
  font-weight: 700;
  margin-left: 2em;
  margin-top: 0.7em;
  display: block;
  transition: all 0.3s;
}

.form__input:placeholder-shown ~ .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4em);
}

.form__radio-group {
  width: 45%;
  display: inline-block;
}
@media only screen and (max-width: 56.25em) {
  .form__radio-group {
    width: 100%;
    margin-bottom: 2em;
  }
}

.form__radio-input {
  display: none;
}

.form__radio-label {
  font-size: 1.6em;
  cursor: pointer;
  position: relative;
  padding-left: 4em;
}

.form__radio-button {
  height: 2em;
  width: 2em;
  border: 5px solid #55c57a;
  border-radius: 100%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -0.4em;
}
.form__radio-button::after {
  content: "";
  display: block;
  border-radius: 100%;
  position: absolute;
  height: 1.4em;
  width: 1.4em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #55c57a;
  opacity: 0;
  transition: opacity 0.2s;
}

.form__radio-input:checked ~ .form__radio-label .form__radio-button::after {
  opacity: 1;
}

.popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
@supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
  .popup {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.4);
  }
}
.popup__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  background-color: #fff;
  box-shadow: 0 2em 4em rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: table;
  overflow: hidden;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.25);
  transition: all 0.5s 0.2s;
}
@media only screen and (max-width: 37.5em) {
  .popup__content {
    display: block;
  }
}
.popup__left {
  width: 33.333333%;
  display: table-cell;
}
@media only screen and (max-width: 56.25em) {
  .popup__left {
    vertical-align: middle;
  }
}
@media only screen and (max-width: 37.5em) {
  .popup__left {
    width: 100%;
    display: block;
  }
}
.popup__right {
  width: 66.6666667%;
  display: table-cell;
  vertical-align: middle;
  padding: 3em 5em;
}
.popup__img {
  display: block;
  width: 100%;
}
.popup__text {
  font-size: 1.4em;
  margin-bottom: 2.9em;
  -moz-column-count: 2;
  -moz-column-gap: 4em;
  -moz-column-rule: 1px solid #eee;
  column-count: 2;
  column-gap: 4em;
  column-rule: 1px solid #eee;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
@media only screen and (max-width: 56.25em) {
  .popup__text {
    font-size: 1.2em;
    margin-bottom: 2.7em;
  }
}
@media only screen and (max-width: 37.5em) {
  .popup__text {
    -moz-column-count: 1;
    column-count: 1;
  }
}
.popup:target {
  opacity: 1;
  visibility: visible;
}
.popup:target .popup__content {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.popup__close:link,
.popup__close:visited {
  color: #777;
  position: absolute;
  top: 2.5em;
  right: 2.5em;
  font-size: 3em;
  text-decoration: none;
  display: inline-block;
  transition: all 0.2s;
  line-height: 1;
}
.popup__close:hover {
  color: #55c57a;
}
@media only screen and (max-width: 37.5em) {
  .popup__close {
    background-color: #fff;
    border-radius: 50%;
    padding: 1em 1.6em;
  }
}
@media only screen and (max-width: 37.5em) {
  .popup__img--2 {
    display: none;
  }
}

.header {
  position: relative;
  height: 85vh;
  background-size: cover;
  background-position: top;
}
@supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
  .header {
    height: 95vh;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 95%);
    clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 95%);
  }
}
@media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
  only screen and (-webkit-min-device-pixel-rate: 2) and (min-width: 37.5em),
  only screen and (min-width: 125em) {
}
@media only screen and (max-width: 37.5em) {
  .header {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 95%);
    clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 95%);
  }
}
.header__logo-box {
  position: absolute;
  top: 4em;
  left: 4em;
}
.header__logo {
  height: 3.5em;
}
.header__text-box {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.row {
  max-width: 114em;
  margin: 0 auto;
}
@media only screen and (max-width: 56.25em) {
  .row {
    max-width: 50em;
    padding: 0 3em;
  }
}
.row:not(:last-child) {
  margin-bottom: 8em;
}
@media only screen and (max-width: 56.25em) {
  .row:not(:last-child) {
    margin-bottom: 6em;
  }
}
.row::after {
  content: "";
  display: table;
  clear: both;
}
.row [class^="col-"] {
  float: left;
}
.row [class^="col-"]:not(:last-child) {
  margin-right: 6em;
}
@media only screen and (max-width: 56.25em) {
  .row [class^="col-"]:not(:last-child) {
    margin-right: 0;
    margin-bottom: 6em;
  }
}
@media only screen and (max-width: 56.25em) {
  .row [class^="col-"] {
    width: 100% !important;
  }
}
.row .col-1-of-2 {
  width: calc((100% - 6em) / 2);
}
.row .col-1-of-3 {
  width: calc((100% - 2 * 6em) / 3);
}
.row .col-2-of-3 {
  width: calc(2 * ((100% - 2 * 6em) / 3) + 6em);
}
.row .col-1-of-4 {
  width: calc((100% - 3 * 6em) / 4);
}
.row .col-2-of-4 {
  width: calc(2 * ((100% - 3 * 6em) / 4) + 6em);
}
.row .col-3-of-4 {
  width: calc(3 * ((100% - 3 * 6em) / 4) + (2 * 6em));
}

.navigation__background {
  height: 6em;
  width: 6em;
  border-radius: 50%;
  position: fixed;
  top: 6.5em;
  right: 6.5em;
  background-image: radial-gradient(#7ed56f, #28b485);
  z-index: 1000;
  transition: transform 0.8s cubic-bezier(0.85, 0, 0.07, 1);
}
@media only screen and (max-width: 56.25em) {
  .navigation__background {
    top: 4em;
    right: 4em;
  }
}
@media only screen and (max-width: 37.5em) {
  .navigation__background {
    top: 3em;
    right: 3em;
  }
}

.navigation__checkbox {
  display: none;
}

.navigation__button {
  background-color: #fff;
  height: 7em;
  width: 7em;
  position: fixed;
  top: 6em;
  cursor: pointer;
  right: 6em;
  border-radius: 50%;
  text-align: center;
  z-index: 2000;
  box-shadow: 0 1em 3em rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 56.25em) {
  .navigation__button {
    top: 3.5em;
    right: 3.5em;
  }
}
@media only screen and (max-width: 37.5em) {
  .navigation__button {
    top: 2.5em;
    right: 2.5em;
  }
}

.navigation__item {
  margin: 1em;
}

.navigation__link:link,
.navigation__link:visited {
  display: inline-block;
  font-size: 3em;
  padding: 1em 2em;
  background-size: 220%;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  background-image: linear-gradient(
    120deg,
    transparent 0%,
    transparent 50%,
    #fff 50%
  );
  transition: all 0.4s;
}
.navigation__link:link span,
.navigation__link:visited span {
  margin-right: 1.5em;
  display: inline-block;
}

.navigation__link:hover,
.navigation__link:active {
  background-position: 100%;
  color: #55c57a;
  transform: translateX(1em);
}

.navigation__list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
  width: 100%;
}

.navigation__nav {
  height: 100vh;
  width: 0%;
  position: fixed;
  top: 0;
  text-align: center;
  left: 0;
  z-index: 1500;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(80);
}

.navigation__checkbox:checked ~ .navigation__nav {
  width: 100%;
  opacity: 1;
}

.navigation__icon {
  position: relative;
  margin-top: 3.5em;
}
.navigation__icon,
.navigation__icon::before,
.navigation__icon::after {
  width: 3em;
  height: 2px;
  background-color: #333;
  display: inline-block;
}
.navigation__icon::before,
.navigation__icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}
.navigation__icon::before {
  top: -0.8em;
}
.navigation__icon::after {
  top: 0.8em;
}

.navigation__button:hover .navigation__icon::before {
  top: -1em;
}

.navigation__button:hover .navigation__icon::after {
  top: 1em;
}

.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: transparent;
}

.navigation__checkbox:checked + .navigation__button .navigation__icon::after {
  top: 0;
  transform: rotate(135deg);
}

.navigation__checkbox:checked + .navigation__button .navigation__icon::before {
  top: 0;
  transform: rotate(-135deg);
}
