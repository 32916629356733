.team-home-div {
  text-align: center;

  padding: 2rem;
  background: linear-gradient(rgba(63, 46, 145, 0.65), rgba(5, 1, 15, 0.75)),
    url("../../assets/LandingPage2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.carousel-div {
  padding: 0 4rem;
}

@keyframes leftRight {
  0% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(25%);
  }

  100% {
    transform: translateX(-25%);
  }
}
#teamMore {
  animation: leftRight 2s ease infinite;
  display: inline-block;
  margin-left: 5px;
}
/* .team-home-div-textArea {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 2rem 0;
	display: flex;
	align-items: center;
}*/
.team-home-div-textArea h1 {
  font-weight: 800;
}
.team-home-all {
  color: var(--color-white);
  padding: 1rem;

  font-weight: 600;

  width: fit-content;
  height: fit-content;
  position: relative;
  transition: all 0.2s ease;
}
.team-home-all::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 28px;
  background: var(--color-secondary);
  width: 56px;
  height: 56px;
  transition: all 0.3s ease;
}
.team-home-all span {
  position: relative;
  font-size: 16px;

  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  vertical-align: middle;
}
.team-home-all:hover::before {
  width: 100%;
  background: #8c58ff;
}
.team-home-all:hover {
  color: #fff;
}
.team-home-all:active {
  transform: scale(0.95);
}
