/* Hex Grid Styles */
.main {
  display: flex;
  --s: 180px; /* size  */
  --m: 1px; /* margin */
  --f: calc(var(--s) * 1.732 + 4 * var(--m) - 1px);
  padding: 0 15%;
  padding-bottom: 10%;
}
.teamMember--name {
  text-transform: capitalize;
}
.hexGrid {
  font-size: 0;
}

.hexGrid > div {
  width: var(--s);
  margin: var(--m);
  height: calc(var(--s) * 1.1547);
  display: inline-block;
  font-size: initial;
  margin-bottom: calc(var(--m) - var(--s) * 0.2886);
  filter: url(#round);
  position: relative;
}

.hexGrid > div:hover {
  width: calc(var(--s) * 1.1);
  transition: 0.6s ease-in-out;
  margin-left: -10px;
}

.hexGrid::before {
  content: "";
  display: block;
  width: calc(var(--s) / 2 + var(--m));
  float: left;
  height: 120%;
  shape-outside: repeating-linear-gradient(
    #0000 0 calc(var(--f) - 3px),
    #000 0 var(--f)
  );
}

.test {
  display: inline-block;
  width: 450px;
  color: red;
  margin: 20px;
  filter: url(#round);
}

.back_img {
  transform: rotate(-90deg);
  height: 180px;
  background-size: cover;
}

.hex {
  content: "";
  display: block;
  padding-top: 86.6%;
  background-size: cover;
  /* background-color: var(--color-secondary); */
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  transform: rotate(90deg);
  background-repeat: no-repeat;
}

.displayHover:hover {
  transition: 0.8s;
  opacity: 1 !important;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    10%,
    rgba(0, 0, 0, 0.5),
    90%,
    rgba(0, 0, 0, 0.1)
  );
}

.displayHover:hover .back_img {
  margin: 0 !important;
}

.socialHover:hover {
  color: var(--color-secondary) !important;
}

@media screen and (max-width: 1024px) {
  .main {
    --s: 170px; /* size  */
    padding: 0 7%;
    padding-bottom: 10%;
  }

  .back_img {
    transform: rotate(-90deg);
    height: 170px;
    background-size: cover;
  }
}

@media screen and (max-width: 900px) {
  .main {
    --s: 170px; /* size  */
    padding: 0 10%;
    padding-bottom: 10%;
  }
  .back_img {
    transform: rotate(-90deg);
    height: 170px;
    background-size: cover;
  }
}

@media screen and (max-width: 458px) {
  .main {
    --s: 140px; /* size  */
    padding: 0;
    padding-bottom: 10%;
  }
  .back_img {
    transform: rotate(-90deg);
    height: 140px;
    background-size: cover;
  }
}
