/* sticky navbar and set navbar height*/
.navdiv {
	position: fixed;
	top: 0px;
	width: 100vw;
	z-index: 100;
}

.blacknav {
	background-color: rgba(0, 0, 0, 0.9);
}

@media only screen and (max-width: 992px) {
	.nav {
		background-color: rgba(0, 0, 0, 0.9);
	}
}

/* overflow visible on navbar expand (for mobile view) */
.nav {
	overflow: visible;
	padding: 1.5rem 0rem 1.5rem 0rem;
}

.navbar {
	--bs-navbar-padding-y: 0.625rem;
}

.navbar-brand {
	user-select: none;
	-webkit-user-drag: none;
}

.navbar-nav {
	font-size: 1rem;
	font-weight: 600;
}

/* align nav-links in left side in mobile view and set color of nav-links*/
.nav-link {
	text-align: left;
	color: white;
	user-select: none;
	-webkit-user-drag: none;
}
@media only screen and (max-width: 992px) {
	.nav-link {
		border-bottom: 0.5px solid #c9c9c9;
	}
}

/* padding of nav links */
.nav-link {
	--bs-navbar-nav-link-padding-x: 1rem;
}

/* left and right side padding of navbar */
.container {
	padding-right: calc(var(--bs-gutter-x));
	padding-left: calc(var(--bs-gutter-x) * 1.25);
}

/* addjusting the text beside CSS logo (full name of CSS) */
#css_full_name {
	color: white;
	font-size: 1.35rem;
	line-height: 1.1rem;
	display: inline-block;
	padding: 0.3rem 0.3rem 0.3rem 0rem;
	text-align: left;
	/* font-family: 'Teko', sans-serif; */
	font-family: "Montserrat", sans-serif;
}

/* nav link hover and focus effect */
.nav-link:focus,
.nav-link:hover {
	-webkit-text-stroke: 0.8px var(--color-secondary);
}

/* set navbar toggler color */
.navbar-toggler {
	border-color: white;
	color: white;
}

/*navbar toggler focus*/
.navbar-toggler:focus {
	box-shadow: 0 0 0 0.1rem;
}

/* CSS logo */
#navCSSlogo {
	width: 78px;
	user-select: none;
	-webkit-user-drag: none;
}
