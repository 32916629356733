@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-secondary: #8c75ff;
  --color-black: rgb(14, 14, 14);
  --color-white: #fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

p {
  user-select: text;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.color-secondary {
  color: var(--color-secondary);
}

.color-white {
  color: #fff !important;
}

/* Landing Page Styles */
@keyframes updown {
  0% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(25%);
  }

  100% {
    transform: translateX(-25%);
  }
}

#downscroll {
  rotate: 90deg;
  animation: updown 2s ease infinite;
}

.background-clip {
  background: linear-gradient(rgba(63, 46, 145, 0.75), rgba(5, 1, 15, 0.75)),
    url(/src/assets/LandingPage.webp);
  /* -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 30%, 0 70%);
	clip-path: polygon(0% 0%, 100% 0, 100% 30%, 0 70%); */
}

.team-text {
  color: var(--color-secondary);
}

.bottom-clip {
  -webkit-clip-path: polygon(100% 0, 65% 100%, 100% 100%);
  clip-path: polygon(100% 0, 65% 100%, 100% 100%);
  background: linear-gradient(rgba(63, 46, 145, 0.75), rgba(5, 1, 15, 0.75)),
    url(/src/assets/LandingPage.webp);
}
.hoverEffect:hover{
  transform: scale(1.06);
  box-shadow: 0 0 15px var(--color-secondary);
  transition:ease-in-out
}