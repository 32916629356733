.about {
  padding: 3% 8.5%;
}
.flexabout {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-evenly;
}

.flexaboutbox {
  margin: 3.25% 0 1%;
  /* background-color: rgb(247, 247, 247); */
  padding: 3% 2%;
  width: 33.33%;
}

@media only screen and (max-width: 992px) {
  .flexaboutbox {
    width: 90%;
  }
}

.whoareweHeadline,
.visionHeadline,
.journeyHeadline {
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 600;
  text-align: left;
}

.headlineSpan {
  font-size: 2.2rem;
  display: block;
  line-height: 0.5;
  margin-bottom: 2rem;
  font-weight: 800;
}

.aboutHeadline {
  text-align: center;
  font-size: 2.6rem;
  font-weight: 800;
  position: relative;
}

.aboutHeadline::after {
  content: " ";
  display: inline-block;
  background-image: linear-gradient(
    to right,
    var(--color-secondary) 50%,
    rgb(255, 255, 255) 50%,
    #fff 52%,
    rgb(0, 0, 0) 50%
  );
  height: 0.5rem;
  border-radius: 2rem;
  width: 10rem;

  position: absolute;
  top: 100%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.whoareweContent,
.visionContent,
.JourneyContent {
  text-align: left;
}

/* @keyframes leftright {
    0% {
        background-position: right;
    }
  
    50% {
        background-position: left;
    }
    100% {
        background-position: right;
    }

  } */

.aboutHeadlineunderline {
  height: 5px;
  width: 15rem;
  margin: auto;
  background: linear-gradient(
    to right,
    var(--color-secondary) 50%,
    rgb(0, 0, 0) 50%
  );
  /* background-size: 200% 100%; */
  /* animation: leftright 2s ease infinite; */
}

@media only screen and (max-width: 992px) {
  .whoareweHeadline,
  .visionHeadline,
  .journeyHeadline,
  .whoareweContent,
  .visionContent,
  .JourneyContent {
    text-align: center;
  }
}
