/* .teamCard-div {
  background-color: #fff;
  width: 300px;
  margin: auto;
}

.card-img-div img {
  height: 300px;
}
.card-text-div {
  padding: 1rem 0;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0.5rem;
}
.card-text-div h1 {
  font-weight: 800;
}
.card-text-div h3 {
  font-weight: 500;
}
.team-card-social {
  display: flex;
  justify-content: space-around;
}
.social-icon-prop:hover {
  color: #8c75ff;
  transform: scale(120%);
  cursor: pointer;
}
@media (max-width: 1300px) {
  .teamCard-div {
    width: 270px;
  }

  .card-img-div img {
    height: 280px;
  }
  .card-text-div {
    padding: 0.8rem 0;

    gap: 0.4rem;
  }
  .card-text-div h1 {
    font-weight: 700;
  }
  .card-text-div h3 {
    font-weight: 500;
  }
}

@media (max-width: 1170px) {
  .teamCard-div {
    width: 260px;
  }

  .card-img-div img {
    height: 250px;
  }
  .card-text-div {
    padding: 0.5rem 0;

    gap: 0.3rem;
  }
  .card-text-div h1 {
    font-weight: 700;
  }
  .card-text-div h3 {
    font-weight: 500;
  }
}
@media (max-width: 860px) {
  .teamCard-div {
    width: 200px;
  }

  .card-img-div img {
    height: 200px;
  }
  .card-text-div {
    padding: 0.3rem 0;

    gap: 0.2rem;
  }
  .card-text-div h1 {
    font-weight: 600;
  }
  .card-text-div h3 {
    font-weight: 500;
  }
}
@media (max-width: 680px) {
  .teamCard-div {
    width: 180px;
  }

  .card-img-div img {
    height: 180px;
  }
  .card-text-div {
    padding: 0.3rem 0;

    gap: 0.3rem;
  }
  .card-text-div h1 {
    font-weight: 700;
  }
  .card-text-div h3 {
    font-weight: 500;
  }
}
.slick-track {
  margin: auto;
} */

.teamCard-div {
  background-color: var(--color-white);
  margin: 6rem 1rem 2rem;
  position: relative;
  min-height: 10rem;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
}

.card-img-div {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  left: 50%;
  width: 7rem;
  height: 7rem;
}

.card-img-div > img {
  height: auto;
  border-radius: 50%;
  object-fit: cover;
  height: 7rem;
  overflow: hidden;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  outline-offset: 0.1rem;
  outline: 4px solid transparent;
}

.card-img-div > img:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
  outline-offset: 0.4rem;
  outline: 4px solid var(--color-secondary);
}

.card-text-div {
  padding-top: 3.5rem;
  border: 1px solid #efefef;
  border-radius: 0.5rem;
}

.card-text-div h1 {
  font-weight: 800;
  font-size: 1.2rem;
}
.card-text-div h3 {
  font-weight: 500;
  font-size: 1rem;
}

.card-text-div > h3::after {
  content: "";
  display: block;
  width: 3rem;
  height: 3px;
  border-radius: 1rem;
  background-color: var(--color-secondary);
  margin: 0.5rem auto;
}

.team-card-social {
  margin: 1rem auto 0;
  display: flex;
  align-items: center;
  gap: 1rem;  
  box-sizing: border-box;
  justify-content: center;
  background-color: var(--color-black);
  padding: 0.7rem 0;
  border-radius: 0 0 0.5rem 0.5rem;
}

.social-icon-prop {
  box-sizing: border-box;
  color: var(--color-white);
  font-size: 1.5rem;
  cursor: pointer;
  margin: 0 0.5rem;
}
