.faculty-advisor-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 5rem;
  padding: 5rem 5rem;
  background-color: #181818;
  color: #fff;
}
.faculty-advisor-text {
  font-size: 3.5rem;
  font-weight: 800;
  align-self: center;
}

.faculty-advisor-iamge {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.fac-img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
  width: 135px;
  height: 135px;
  border: 1px solid #fff;
  border-radius: 50%;
  outline-offset: 1rem;
  outline: 4px solid transparent;
  align-self: center;
  margin-bottom: 2rem;
  object-fit: cover;
}

.fac-img:hover {

  outline-offset: 0.5rem;
  border: none;
  outline: 4px solid var(--color-secondary);
}
.fac-dwn {
  display: flex;
  flex-direction: column;
  align-self: end;
}
.fac-up {
  display: flex;
  flex-direction: column;
  align-self: start;
}
.fac-advisor-heading {
  font-weight: 800;
  text-align: center;
}
.fac-img-div h3,
.fac-img-div h6 {
  text-align: center;
}
.fac-img-div h3 {
  font-size: 1.3rem;
  font-weight: 600;
}
.fac-img-div h6 {
  margin-top: 0.5rem;
  font-size: 0.9rem;
}
@media (min-width: 1100px) {
  .faculty-advisor-container {
    min-height: 450px;
  }
}
@media (min-width: 990.1px) {
  .purp-color {
    color: #8c75ff;
  }
}

@media (max-width: 990px) {
  .fac-advisor-heading::after {
    content: " ";
    display: inline-block;
    background-image: linear-gradient(
      to right,
      #fff 50%,
      #000 50%,
      #000 52%,
      var(--color-secondary) 50%
    );
    height: 0.5rem;
    border-radius: 2rem;
    width: 10rem;

    position: absolute;
    top: 120%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
  .faculty-advisor-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    gap: 2rem;
    padding: 2rem 0;
  }
  .fac-dwn,
  .fac-up {
    align-self: self-start;
  }
  .faculty-advisor-text h1,
  .event-section-heading {
    color: #fff;
  }
  .faculty-advisor-text h1 p {
    display: inline-block;
  }
  .faculty-text {
    color: var(--color-secondary);
  }
}
@media (max-width: 750px) {
  .faculty-advisor-container {
    grid-template-rows: 0.5fr 2fr;
  }
  .faculty-advisor-iamge {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
@media (max-width: 390px) {
  .faculty-advisor-container {
    grid-template-rows: 0.5fr 2.5fr;
    gap: 1rem;
    padding: 0.5rem 0;
  }
  .faculty-advisor-iamge {
    grid-template-columns: 1fr;
  }
  .faculty-advisor-text h1,
  .event-section-heading {
    font-size: 2.5rem;
  }
}
