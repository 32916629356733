.footer {
	background-color: var(--color-black);
	color: #d3d3d3;
	padding: 4% 6% 0rem;
}

.footer-container {
	display: flex;
	/* grid-template-columns: 1fr 30% 30%; */
	/* grid-column-gap: 2rem; */
	flex-wrap: wrap;
	justify-content: center;
}

.footer_item {
	width: 33%;
	padding: 2rem;
}

@media only screen and (max-width: 992px) {
	.footer_item {
		width: 100%;
		text-align: center;
	}
}

.footer_logo {
	height: 3rem;
	width: auto;
	margin-bottom: 2rem;
}

.footer_heading {
	text-align: left;
	font-size: 2.1rem;
	font-weight: 800;
}

.footer_text {
	text-align: left;
	font-weight: 400;
	margin-top: 1rem;
}

.footer_socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 3rem;
	column-gap: 1rem;
	font-size: 1.3rem;
}

.footer_socials > a {
	border-radius: 50%;
	padding: 0.8rem;
	transition: all 0.2s ease-in-out;
	border: 1px solid var(--color-secondary);
}

.footer_socials > a:hover {
	background-color: var(--color-secondary);
	color: #fff;
}

.footer_title {
	font-size: 1.3rem;
	font-weight: 800;
	color: #fff;
	margin-bottom: 1rem;
	z-index: 100;
	text-align: left;
	position: relative;
}
.footer_title::after {
	content: " ";
	display: block;
	position: absolute;
	transform: translateY(-50%);
	background-color: var(--color-secondary);
	height: 0.2rem;
	width: 6rem;
	left: 0%;
	z-index: -1;
	border-radius: 2rem;
	top: 150%;
}
/* .footer_title::after {
  content: " ";
  display: block;
  position: absolute;
  transform: translateY(-50%);
  background-color: var(--color-secondary);
  height: 2.4rem;
  width: 6rem;
  left: -3%;
  z-index: -1;
  border-radius: 2rem;
  top: 50%;
} */

.footer_links {
	display: flex;
	flex-direction: column;
	column-gap: 1rem;
	margin-top: 3rem;
	text-align: left;
}

.footer_link {
	font-size: 1.2rem;
	font-weight: 500;
	border-bottom: 0.5px solid #c9c9c9;
	transition: all 0.2s ease-in-out;
	width: 100%;
	padding: 0.6rem 0.2rem;
	border-bottom: 0.5px solid #c9c9c9;
}

.footer_link > a {
	font-weight: 500;
}

.footer_link:hover {
	border-bottom: 0.5px solid var(--color-secondary);
	width: 70%;
}

.footer_link:hover > a {
	text-decoration: none;
	color: #fff;
	transform: scale(1.1);
}

.footer-details {
	text-align: left;
	margin-top: 3rem;
}

.footer-detail {
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-column-gap: 1.3rem;
	margin-bottom: 1rem;
}
.footer-detail-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3rem;
	border-radius: 50%;
	width: 3rem;
	border: 1.6px solid var(--color-secondary);
	font-size: 1.5rem;
}
.footer-detail-title {
	font-weight: 600;
	color: var(--color-secondary);
}

.footer-detail-text {
	font-weight: 400;
	margin-bottom: 1rem;
}

/* footer-copyright */
.footer-copyright {
	background-color: var(--color-black);
	color: #d3d3d3;
	padding: 2% 0;
	border-top: 1px solid #373737;
	text-align: center;
	font-weight: 500;
}
