.landing {
	min-height: 100vh;
	width: 100vw;
	background: linear-gradient(rgba(63, 46, 145, 0.75), rgba(5, 1, 15, 0.75)),
		url(../../assets/LandingPage.webp);
	background-repeat: no-repeat;
	background-size: cover;
}

.landingContent {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 28vh 26vw;
	font-family: "Poppins", sans-serif;
	color: white;
}

.landingSpan1 {
	color: var(--color-secondary);
}
#landingText1 {
	font-size: 5rem;
	font-weight: 700;
}
.landingSeperator {
	color: var(--color-secondary);
}

#landingText2 {
	font-size: 2.5rem;
	font-weight: 700;
}

.scrollButton {
	color: white;
	background: var(--color-secondary);
	border-radius: 10rem;
	margin: 5vh;
	padding: 1rem;
	border: 0px;
}
.scrollButton:hover {
	transform: scale(1.1);
}
.landingText3 {
	font-weight: 300;
	margin-top: 8.7%;
	text-align: center;
}

@keyframes updown {
	0% {
		transform: translateX(-25%);
	}

	50% {
		transform: translateX(25%);
	}

	100% {
		transform: translateX(-25%);
	}
}

#downscroll {
	rotate: 90deg;
	font-size: 1.6rem;
	animation: updown 2s ease infinite;
}
#downscroll:hover {
	transform: scale(1.1);
}
@media only screen and (max-width: 1122px) {
	#landingText2 {
		font-size: 2rem;
	}
}
@media only screen and (max-width: 897px) {
	#landingText2 {
		font-size: 1.75rem;
	}
}
@media only screen and (max-width: 785px) {
	#landingText2 {
		font-size: 1.5rem;
	}
}
@media only screen and (max-width: 507px) {
	#landingText2 {
		font-size: 1.5rem;
	}
}

@media only screen and (max-width: 977px) {
	#landingText1 {
		font-size: 4rem;
	}
}
@media only screen and (max-width: 487px) {
	#landingText1 {
		font-size: 3.5rem;
	}
}

@media only screen and (max-width: 782px) {
	.landingContent {
		padding: 28vh 18vw;
	}
}
@media only screen and (max-width: 751px) {
	.landingContent {
		padding: 28vh 13vw;
	}
}
@media only screen and (max-width: 673px) {
	.landingContent {
		padding: 28vh 10vw;
	}
}
@media only screen and (max-width: 507px) {
	.landingContent {
		padding: 30vh 5vw;
	}
}
